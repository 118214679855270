import "../scss/app.scss";
import Vue from "vue";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
const bs = require("./bs");
gsap.registerPlugin(ScrollTrigger);
Vue.directive("scroll", {
  inserted: function(el, binding) {
    let f = function(evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  },
});
const app = new Vue({
  el: "#app",
  data: {
    spnav: false,
    toggleMenu: false,
  },
  mounted() {
    bs.bootstrapCollapse();
    bs.bootstrapCarousel();
    this.InView();
    this.topScroll();
  },
  methods: {
    gnavInnerOpen(e) {
      e.target.nextElementSibling.classList.toggle("active");
    },
    InView() {
      const trigger = document.querySelectorAll("[data-trigger]");
      trigger.forEach(function(userItem) {
        ScrollTrigger.create({
          trigger: userItem,
          start: "top bottom-=50px",
          end: "bottom top+=50px",
          toggleClass: { targets: userItem, className: "InView" },
        });
      });
    },
    topScroll() {
      ScrollTrigger.create({
        trigger: ".topScrollIn",
        start: "top bottom-=50px",
        end: "bottom top+=50px",
        toggleClass: { targets: "#header", className: "topScroll" },
      });
    },
    handleScroll: function(evt, el) {
      if (window.scrollY > 145) {
        el.classList.add("topScroll");
      } else {
        el.classList.remove("topScroll");
      }
      // return window.scrollY > 100;
    },
  },
});
