const Collapse = require("bootstrap/js/dist/collapse");

exports.bootstrapCollapse = () => {
  var collapseElementList = [].slice.call(
    document.querySelectorAll(".collapse")
  );
  var collapseList = collapseElementList.map(function(collapseEl) {
    return new Collapse(collapseEl);
  });
};

const Carousel = require("bootstrap/js/dist/carousel");

exports.bootstrapCarousel = () => {
  var myCarousel = document.querySelector("#myCarousel");
  var carousel = new Carousel(myCarousel, {
    interval: 2000,
    pause: false,
    ride: carousel,
    wrap: true,
  });
};
